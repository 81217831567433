<template>
  <div>
    <div class="banner" :style="cover"></div>
    <v-card class="blog-container">
      <button type="submit" @click="payment" style="background-color: #4682B4; color: white; padding: 1px;">充值</button>
      <timeline>
        <timeline-title> 余额: {{ balance }} </timeline-title>
        <timeline-item v-for="item of archiveList" :key="item.id">
          <div class="item-status" :class="statusClass(item)">
            <span class="time">{{ item.createdAt.substring(0, 19).replace("T", " ") }}</span>
            <span class="separator">|</span>{{ item.orderName }}<span class="separator">|</span>金额￥： {{ item.amount }}
          </div>
        </timeline-item>
      </timeline>
      <!-- 分页按钮 -->
      <v-pagination color="#00C4B6" v-model="current" :length="Math.ceil(count / 10)" total-visible="7" />
    </v-card>
  </div>
</template>
  
<script>
import { Timeline, TimelineItem, TimelineTitle } from "vue-cute-timeline";
export default {
  created() {
    this.listArchives();
    this.fetchBalance();
  },
  components: {
    Timeline,
    TimelineItem,
    TimelineTitle
  },
  data: function () {
    return {
      balance: 0.0,
      current: 1,
      count: 0,
      archiveList: [],
      amount: 1,
    };
  },
  methods: {
    fetchBalance() {
      this.axios.get("/api/balance").then(({ data }) => {
        if (data.flag) {
          this.balance = data.data.balance;
        } else {
          this.$toast({ type: "error", message: data.message });
        }
      });
    },
    listArchives() {
      this.axios
        .get("/api/order/list", {
          params: { current: this.current }
        })
        .then(({ data }) => {
          this.archiveList = data.data.recordList;
          this.count = data.data.count;
          // this.$myMsg.notify({ content: '加载成功', type: 'success', noNotifyBtn: false, time: 1000 });
        });
    },
    statusClass(item) {
      switch (item.status) {
        case 'TRADE_SUCCESS':
          return 'status-done';
        case 'WAIT_BUYER_PAY':
          return 'status-process';
        case 'TRADE_CLOSED':
          return 'status-cancel';
        case 'TRADE_FINISHED':
          return 'status-done';
        default:
          return '';
      }
    },
    payment() {
      this.$store.state.payFlag = true;
    }
  },
  computed: {
    cover() {
      var cover = "";
      this.$store.state.blogInfo.pageList.forEach(item => {
        if (item.pageLabel == "order") {
          cover = item.pageCover;
        }
      });
      return "background: url(" + cover + ") center center / cover no-repeat";
    },
  },
  watch: {
    current(value) {
      this.axios
        .get("/api/order/list", {
          params: { current: value }
        })
        .then(({ data }) => {
          this.archiveList = data.data.recordList;
          this.count = data.data.count;
        });
    }
  }
};
</script>
  
<style scoped>
.time {
  font-size: 0.75rem;
  color: #555;
  margin-right: 1rem;
}

.status-done {
  background: #e6ffe6;
  color: #33cc33;
}

.status-process {
  background: #f1f1f1;
  color: #999;
}

.status-cancel {
  background: #ffe6e6;
  color: #ff9999;
}
</style>
  